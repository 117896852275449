<template>
  <!-- 添加通知 -->
  <div class="container">
    <a-page-header
      :title="title"
      :subTitle="subTitle"
      @back="() => $router.go(-1)"
    />
    <div class="main-content">
      <div class="body">
        <a-form-model ref="ruleForm" :model="form" :rules="rules">
          <a-form-model-item label="类型" prop="type">
            <a-select v-model="form.type" placeholder="请选择通知类型">
              <a-select-option :value="1">
                平台公告
              </a-select-option>
              <a-select-option :value="2">
                活动通知
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item ref="title" label="标题" prop="title">
            <a-input
              v-model="form.title"
              placeholder="请输入标题"
              @blur="
                () => {
                  $refs.title.onFieldBlur()
                }
              "
            />
          </a-form-model-item>
          <a-form-model-item ref="text" label="正文" prop="text">
            <tinymce ref="editor" v-model="form.text" />
          </a-form-model-item>
          <div class="item">
            <a-form-model-item label="发布方式" prop="issue">
              <a-select
                v-model="form.issue"
                placeholder="请选择发布方式"
                @change="issueChange"
              >
                <a-select-option :value="1">
                  仅新增（不发布）
                </a-select-option>
                <a-select-option :value="2">
                  定时发布
                </a-select-option>
                <a-select-option :value="3">
                  即时发布
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              class="form-model-item-2"
              prop="startAt"
              v-if="form.issue === 2"
            >
              <a-date-picker
                v-model="startAt"
                format="YYYY-MM-DD"
                @change="onStartAtChange"
              />
            </a-form-model-item>
          </div>
          <div class="item">
            <a-form-model-item label="下线方式" prop="display">
              <a-select
                v-model="form.display"
                placeholder="请选择下线方式"
                @change="displayChange"
              >
                <a-select-option :value="1">
                  定时下线
                </a-select-option>
                <a-select-option :value="2">
                  长期（人工下线）
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              class="form-model-item-2"
              prop="endAt"
              v-if="form.display === 1"
            >
              <a-date-picker
                v-model="endAt"
                format="YYYY-MM-DD"
                @change="onEndAtChange"
              />
            </a-form-model-item>
          </div>
          <a-form-model-item>
            <a-button class="submit-btn" type="primary" @click="onSubmit">
              保存
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
import tinymce from '../../components/tinymce'
import moment from 'moment'
let type = null
let noticeId = null
export default {
  data() {
    return {
      title: '',
      subTitle: '',
      form: {
        type: undefined,
        title: '',
        text: '',
        startAt: '',
        endAt: '',
        issue: undefined,
        display: undefined
      },
      startAt: undefined,
      endAt: undefined,
      rules: {
        // 绑定地区
        type: [
          { required: true, message: '请选择通知类型', trigger: 'change' }
        ],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        text: [{ required: true, message: '请输入正文', trigger: 'blur' }],
        startAt: [
          { required: true, message: '请选择上线日期', trigger: 'change' }
        ],
        endAt: [
          { required: true, message: '请选择下线日期', trigger: 'change' }
        ],
        issue: [
          { required: true, message: '请选择发布方式', trigger: 'change' }
        ],
        display: [
          { required: true, message: '请选择下线方式', trigger: 'change' }
        ]
      },
      submitLoading: false
    }
  },
  components: {
    tinymce
  },
  created() {
    this.title = this.$getPageTitle()
    const query = this.$route.query
    type = Number(query.type)
    noticeId = query.id
    this.subTitle = type === 1 ? '用户小程序' : '师傅小程序'

    if (noticeId) {
      this.getNoticeInsideInfo()
    }
  },
  methods: {
    getNoticeInsideInfo() {
      // 获取通知详情
      const data = {
        id: noticeId,
        type: type
      }
      this.$axios.getNoticeInsideInfo(data).then((res) => {
        const d = res.data.data
        let startAt = undefined
        let endAt = undefined
        if (d.startAt) {
          startAt = d.startAt.substring(0, 10)
        }
        if (d.endAt) {
          endAt = d.endAt.substring(0, 10)
        }
        this.form = {
          id: d.id,
          type: d.type,
          title: d.title,
          text: d.text,
          startAt: d.startAt,
          endAt: d.endAt,
          issue: d.issue,
          display: d.display
        }
        this.startAt = startAt ? moment(startAt, 'YYYY-MM-DD') : undefined
        this.endAt = endAt ? moment(endAt, 'YYYY-MM-DD') : undefined
      })
    },
    issueChange(e) {
      // 发布方式
      if (e !== 2 && this.form.startAt) {
        this.form.startAt = ''
        this.startAt = undefined
      }
    },
    displayChange(e) {
      // 下线方式
      if (e === 2 && this.form.endAt) {
        this.form.endAt = ''
        this.endAt = undefined
      }
    },
    onStartAtChange(date, dateString) {
      // 上线时间
      this.form.startAt = dateString + ' 00:00:00'
    },
    onEndAtChange(date, dateString) {
      // 下线时间
      this.form.endAt = dateString + ' 23:59:59'
    },
    onSubmit() {
      // 提交
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submitLoading = true
          if (noticeId) {
            // 编辑
            if (type === 1) {
              this.changeClientUserNoticeInside()
            } else {
              this.changeClientMasterNoticeInside()
            }
          } else {
            // 新增
            if (type === 1) {
              this.addClientUserNoticeInside()
            } else {
              this.addClientMasterNoticeInside()
            }
          }
        }
      })
    },
    addClientUserNoticeInside() {
      // 用户端
      this.$axios.addClientUserNoticeInside(this.form).then(() => {
        this.submitLoading = false
        this.$message.success('操作成功')
        this.back()
      })
    },
    addClientMasterNoticeInside() {
      // 师傅端
      this.$axios.addClientMasterNoticeInside(this.form).then(() => {
        this.submitLoading = false
        this.$message.success('操作成功')
        this.back()
      })
    },
    changeClientUserNoticeInside() {
      // 用户端
      this.$axios.changeClientUserNoticeInside(this.form).then(() => {
        this.submitLoading = false
        this.$message.success('操作成功')
        this.back()
      })
    },
    changeClientMasterNoticeInside() {
      // 师傅端
      this.$axios.changeClientMasterNoticeInside(this.form).then(() => {
        this.submitLoading = false
        this.$message.success('操作成功')
        this.back()
      })
    },
    back() {
      setTimeout(() => {
        this.$router.go(-1)
      }, 500)
    }
  }
}
</script>

<style scoped>
.body {
  padding: 32px;
  background-color: #fff;
}

.ant-form-item {
  display: flex;
}

.body >>> .ant-form-item-label {
  flex: 0 0 6em;
}

.body >>> .ant-form-item-control-wrapper {
  flex: 1;
}

.item {
  display: flex;
}

.ant-select {
  width: 184px;
}

.form-model-item-2 {
  margin-left: 8px;
}

.ant-calendar-picker {
  width: 224px;
}

.submit-btn {
  margin-left: 6em;
}
</style>
